import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cache from "../utils/cache";
import {
  SELECTED_TOWNS,
  FACEBOOK_TOWN_IDS,
  POST_ON_FACEBOOK,
  MAIN_TOWN_IDS,
  ORGANIZATION_TYPES,
} from "../components/Diy/diyConstants";
import { cloneWithoutReferences } from "../utils/helper";
import { removeTownListener } from "../utils/listeners";
import theme from "../common/Theme/themes/default";
import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

const DiyContext = React.createContext({});

const DiyContextProvider = ({ children }) => {
  const [selectedTownsDiy, setSelectedTownsDiy] = useState([]);
  const [town, setTown] = useState();
  const [formType, setFormType] = useState();
  const [contributionType, setContributionType] = useState();
  const [adFree, setAdFree] = useState(false);
  const [adFlag, setAdFlag] = useState(false);

  const getSelectedTowns = () => {
    return cache.get(SELECTED_TOWNS);
  };
  const showOrHideFacebookBlock = () => {
    const showOrHideFacebookBlockEvent = new CustomEvent(
      "showOrHideFacebookBlockComponent",
      {
        bubbles: true,
        detail: {},
      }
    );
    window.dispatchEvent(showOrHideFacebookBlockEvent);
  };

  const updateShoppingSummary = (towns) => {
    const updateSummaryPageEvent = new CustomEvent("updateSummaryComponent", {
      bubbles: true,
      detail: { towns },
    });
    window.dispatchEvent(updateSummaryPageEvent);
  };

  const updateFacebookTown = (town, isSelected) => {
    const selectedTowns = cloneWithoutReferences(cache.get(SELECTED_TOWNS));
    const selectedTownsWithFacebook = selectedTowns.map((selectedTown) => {
      if (selectedTown?.id === town?.id) {
        return { ...selectedTown, fb_selected: isSelected };
      }
      return selectedTown;
    });

    cache.set(SELECTED_TOWNS, selectedTownsWithFacebook);
    const facebookTowns = selectedTownsWithFacebook
      .filter((town) => town.fb_selected)
      .map((town) => town.id);

    cache.set(FACEBOOK_TOWN_IDS, facebookTowns);
    updateShoppingSummary(selectedTownsWithFacebook);
  };

  const addSelectedTownDiy = (clickedTown) => {
    const cacheTowns = cloneWithoutReferences(cache.get(SELECTED_TOWNS));
    cacheTowns.push(clickedTown);
    setSelectedTownsDiy(cacheTowns);
  };

  const handleSelectSingleTown = (town) => {
    cache.set(SELECTED_TOWNS, town ? [town] : []);
    setSelectedTownsDiy(cache.get(SELECTED_TOWNS));
  };

  const removeSelectedTownDiy = (clickedTown) => {
    const selectedTowns = getSelectedTowns();
    const foundItemToRemoveIndex = selectedTowns.findIndex(
      (town) => town.id === clickedTown.id
    );
    if (foundItemToRemoveIndex >= 0) {
      selectedTowns.splice(foundItemToRemoveIndex, 1);
      cache.set(SELECTED_TOWNS, selectedTowns);
      setSelectedTownsDiy(getSelectedTowns());
    }
    const facebookTowns = cache
      .get(SELECTED_TOWNS)
      .filter((town) => town.fb_selected)
      .map((town) => town.id);
    cache.set(FACEBOOK_TOWN_IDS, facebookTowns);
  };

  const addMultipleTownsDiy = (towns, isInitialLoad = false) => {
    const cacheTowns = isInitialLoad
      ? []
      : cloneWithoutReferences(selectedTownsDiy);
    for (let i = 0; i < towns.length; i++) {
      const foundItemToRemoveIndex = cacheTowns.findIndex(
        (cacheTown) => cacheTown.id === towns[i].id
      );
      if (foundItemToRemoveIndex === -1) {
        cacheTowns.push({ ...towns[i], selected: true });
      }
    }
    setSelectedTownsDiy(cacheTowns);
  };

  const removeMultipleTownsDiy = (towns) => {
    const cacheTowns = cloneWithoutReferences(selectedTownsDiy);
    for (let i = 0; i < towns.length; i++) {
      const foundItemToRemoveIndex = cacheTowns.findIndex(
        (cacheTown) => cacheTown.id === towns[i].id
      );
      cacheTowns.splice(foundItemToRemoveIndex, 1);
    }
    setSelectedTownsDiy(cacheTowns);
  };

  const addItemCountToCart = () => {
    const updateItemCount = new CustomEvent("updateItemCountComponent", {
      bubbles: true,
      detail: {},
    });
    window.dispatchEvent(updateItemCount);
  };

  // This runs first when clicking Facebook checkbox
  const checkFacebookOption = () => {
    const updateCheckFacebookOption = new CustomEvent(
      "updateFacebookOptionComponent",
      {
        bubbles: true,
        detail: {},
      }
    );
    const facebookCheckbox = cache.get(POST_ON_FACEBOOK) === 1;
    const selectedTowns = cloneWithoutReferences(cache.get(SELECTED_TOWNS));
    const selectedTownsWithFacebook = selectedTowns.map((selectedTown) => {
      return { ...selectedTown, fb_selected: facebookCheckbox };
    });

    cache.set(SELECTED_TOWNS, selectedTownsWithFacebook);
    const facebookTowns = selectedTownsWithFacebook
      .filter((town) => town.fb_selected)
      .map((town) => town.id);

    cache.set(FACEBOOK_TOWN_IDS, facebookTowns);
    updateShoppingSummary(selectedTownsWithFacebook);
    window.dispatchEvent(updateCheckFacebookOption);
  };

  const setAllTownsToSelected = () => {
    setSelectedTownsDiy([]);
  };
  removeTownListener(removeSelectedTownDiy);

  const checkAdFreeOption = async () => {
    try {
      const response = await restClient.get(withConfig("AD_FREE_OPTION"));
      setAdFlag(response?.data?.ad_free);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  checkAdFreeOption();

  return (
    <DiyContext.Provider
      value={{
        showOrHideFacebookBlock,
        updateShoppingSummary,
        selectedTownsDiy,
        addSelectedTownDiy,
        removeSelectedTownDiy,
        addMultipleTownsDiy,
        removeMultipleTownsDiy,
        addItemCountToCart,
        checkFacebookOption,
        updateFacebookTown,
        setAllTownsToSelected,
        handleSelectSingleTown,
        setTown,
        town,
        setFormType,
        formType,
        setContributionType,
        contributionType,
        adFree,
        setAdFree,
        adFlag,
      }}
    >
      {children}
    </DiyContext.Provider>
  );
};

DiyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useDiy = () => {
  const diyContextValues = useContext(DiyContext);

  if (!diyContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the DiyContextProvider"
    );
  }

  return diyContextValues;
};

const withDiyContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <DiyContextProvider>
      <Component {...props} />
    </DiyContextProvider>
  );

  return ComponentWithProvider;
};

export { DiyContext, DiyContextProvider, useDiy, withDiyContextProvider };
