import React, { useEffect } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import TextField from "../../../../common/TextField/TextField";
import Typography from "../../../../common/Typography/Typography";
import { useStyles } from "./ClassifiedFields.style";
import useSubmitableContentForm from "../../useSubmitableContentForm";
import {
  MANDATORY_FIELDS_FOR_CLASSIFIED,
  GREEN,
  CONTACT_PERSON_PRIVATE,
  PHONE_NUMBER_PRIVATE,
  CONTACT_PERSON,
  PHONE_NUMBER,
  EMAIL,
  WEBSITE,
  FACEBOOK,
  TWITTER,
  GRAY,
  FACEBOOK_BLUE,
  TWITTER_BLUE,
  HEADLINE,
  FACEBOOK_URL,
  FACEBOOK_EXCERPT,
  POST_ON_FACEBOOK,
  FACEBOOK_TOWN_IDS,
  CONTENT_ID,
  CATEGORIES,
  AD_FREE,
} from "../../diyConstants";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";

const ClassifiedFields = ({ formErrors, content, adFree }) => {
  const classes = useStyles();
  const { setProperty } = useSubmitableContentForm();

  const shouldMarkError = (prop) => {
    let validation;

    validation = MANDATORY_FIELDS_FOR_CLASSIFIED.find(
      (mandatory) => mandatory.key === prop
    );
    if (formErrors.includes(validation.error)) {
      return true;
    }
  };

  useEffect(() => {
    if (content?.id) {
      setProperty(CONTENT_ID, content?.id);
      setProperty(HEADLINE, content?.name);
      setProperty(CONTACT_PERSON_PRIVATE, content?.contact_person_admin);
      setProperty(PHONE_NUMBER_PRIVATE, content?.phone_admin);
      setProperty(CONTACT_PERSON, content?.contact_person);
      setProperty(PHONE_NUMBER, content?.phone);
      setProperty(EMAIL, content?.email);
      setProperty(WEBSITE, content?.website);
      setProperty(FACEBOOK, content?.facebook);
      setProperty(TWITTER, content?.twitter);
      setProperty(FACEBOOK_URL, content?.facebook_client_url);
      setProperty(FACEBOOK_EXCERPT, content?.excerpt);
      setProperty(POST_ON_FACEBOOK, content?.post_on_facebook);
      setProperty(FACEBOOK_TOWN_IDS, content?.town_facebook_ids);
      setProperty(CATEGORIES, content?.categories);
    }
  }, [content]);

  useEffect(() => {
    setProperty(AD_FREE, adFree);
  }, [adFree]);

  return (
    <>
      <div>
        <TextField
          label={labels.HEADLINE}
          onChange={(e) => setProperty(HEADLINE, e.target.value)}
          error={shouldMarkError(HEADLINE)}
          defaultValue={content?.name}
          required
        />
      </div>
      <div className={classes.regular_text_field}>
        <Typography
          level={
            screenWidthIsLowerThan(900) ? "medium_17_px" : "h4_sub_header_4"
          }
          color={GRAY}
          className={classes.display_block}
        >
          {labels.EXPIRE_CLASSIFIEDS}
        </Typography>
      </div>
      <div>
        <Typography
          level={screenWidthIsLowerThan(900) ? "medium_17_px" : "h4"}
          bold
          color={GREEN}
        >
          {labels.PRIVATE_CONTACT_INFO + " "}
        </Typography>
      </div>
      <div className={classes.padding_top_bottom_24}>
        <Typography
          level={
            screenWidthIsLowerThan(900) ? "medium_17_px" : "h4_sub_header_4"
          }
          color={GRAY}
          className={classes.display_block}
        >
          {labels.THIS_WILL_BE_SEEN_BY_ADMIN}
        </Typography>
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.CONTACT_PERSON}
          onChange={(e) => setProperty(CONTACT_PERSON_PRIVATE, e.target.value)}
          error={shouldMarkError(CONTACT_PERSON_PRIVATE)}
          defaultValue={content?.contact_person_admin}
          required
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.PHONE}
          onChange={(e) => setProperty(PHONE_NUMBER_PRIVATE, e.target.value)}
          error={shouldMarkError(PHONE_NUMBER_PRIVATE)}
          defaultValue={content?.phone_admin}
          required
        />
      </div>
      <div>
        <div className={classes.padding_top_bottom_24}>
          <Typography
            level={
              screenWidthIsLowerThan(900) ? "medium_17_px" : "h4_sub_header_4"
            }
            color={GRAY}
            className={classes.display_block}
          >
            {labels.THIS_WILL_BE_SEEN_BY_PUBLIC}
          </Typography>
        </div>
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.CONTACT_PERSON}
          onChange={(e) => setProperty(CONTACT_PERSON, e.target.value)}
          defaultValue={content?.contact_person}
        />
      </div>
      <div className={classes.multiple_textfields_container}>
        <div className={classes.multiple_textfields}>
          <TextField
            label={labels.PHONE}
            onChange={(e) => setProperty(PHONE_NUMBER, e.target.value)}
            defaultValue={content?.phone}
          />
        </div>
        <div className={classes.multiple_textfields}>
          <TextField
            label={labels.EMAIL}
            onChange={(e) => setProperty(EMAIL, e.target.value)}
            defaultValue={content?.email}
          />
        </div>
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.WEBSITE}
          onChange={(e) => setProperty(WEBSITE, e.target.value)}
          defaultValue={content?.website}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.FACEBOOK}
          onChange={(e) => setProperty(FACEBOOK, e.target.value)}
          labelColor={FACEBOOK_BLUE}
          defaultValue={content?.facebook}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.TWITTER}
          onChange={(e) => setProperty(TWITTER, e.target.value)}
          labelColor={TWITTER_BLUE}
          defaultValue={content?.twitter}
        />
      </div>
    </>
  );
};

ClassifiedFields.propTypes = {
  formErrors: PropTypes.arrayOf(PropTypes.string),
  content: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    contact_person_admin: PropTypes.string,
    phone_admin: PropTypes.string,
    contact_person: PropTypes.string,
    phone: PropTypes.string,
    website: PropTypes.string,
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    categories: PropTypes.array,
  }),
};

export default ClassifiedFields;
