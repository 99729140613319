import React, { useEffect, useState } from "react";
import Checkbox from "../../../../../common/Checkbox/Checkbox";
import labels from "../../../../../config/labels";
import { useStyles } from "./CheckboxOptions.style";
import useSubmitableContentForm from "../../../useSubmitableContentForm";
import { PHOTO_REQUIRED, SHOW_ENTITY_LOGO } from "../../../diyConstants";
import Typography from "../../../../../common/Typography/Typography";
import Box from "../../../../../common/Box/Box";
import theme from "../../../../../common/Theme/themes/default";

const CheckboxOptions = ({
  show_entity_logo = false,
  photo_required = false,
}) => {
  const [displayLogo, setDisplayLogo] = useState(show_entity_logo);
  const [defaultPhoto, setDefaultPhoto] = useState(!photo_required);
  const classes = useStyles();
  const { setProperty } = useSubmitableContentForm();

  useEffect(() => {
    setProperty(SHOW_ENTITY_LOGO, displayLogo);
    setProperty(PHOTO_REQUIRED, defaultPhoto === false);
  }, [displayLogo, defaultPhoto]);

  return (
    <Box className={classes.checkbox_container}>
      <Checkbox
        label={
          <Typography
            level="paragraph_1"
            color="gray"
            bold
            className={classes.label}
          >
            {labels.DISPLAY_ENTITY_LOGO}
          </Typography>
        }
        onChange={() => setDisplayLogo(!displayLogo)}
        checked={displayLogo}
        checkboxColor={theme.palette.themeColor.secondary}
      />

      <Checkbox
        label={
          <Typography
            level="paragraph_1"
            color="gray"
            bold
            className={classes.label}
          >
            {labels.DEFAULT_PHOTO}
          </Typography>
        }
        onChange={() => setDefaultPhoto(!defaultPhoto)}
        checked={defaultPhoto}
        checkboxColor={theme.palette.themeColor.secondary}
      />

      {defaultPhoto && (
        <Box className={classes.placeholder_container}>
          <img
            src="https://tapinto-production.s3.us-east-1.amazonaws.com/assets/images/legal_notices/legal_notices_placeholder.jpg"
            alt="tapinto legal notices placeholder"
            className={classes.placeholder}
          />
        </Box>
      )}
    </Box>
  );
};

export default CheckboxOptions;
