import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import TextField from "../../../../common/TextField/TextField";
import Typography from "../../../../common/Typography/Typography";
import { useStyles } from "./BusinessDirectoryListingFields.style";
import useSubmitableContentForm from "../../useSubmitableContentForm";
import {
  MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING,
  GREEN,
  GRAY,
  BUSINESS_NAME,
  BUSINESS_ADDRESS,
  CITY,
  STATE,
  FAX,
  ZIP_CODE,
  PHONE_NUMBER,
  EMAIL,
  CI_WEBSITE,
  CI_FACEBOOK,
  CI_TWITTER,
  CI_NAME_ADMIN,
  CI_PHONE_ADMIN,
  TWITTER_BLUE,
  FACEBOOK_BLUE,
  BUSINESS_ADDRESS2,
  ORGANIZATION_TYPE,
  FACEBOOK_URL,
  FACEBOOK_EXCERPT,
  POST_ON_FACEBOOK,
  FACEBOOK_TOWN_IDS,
  CONTENT_ID,
  AD_FREE,
} from "../../diyConstants";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import Dropdown from "../../../../common/Dropdown/Dropdown";
import { getDropdownChoices } from "../../../../services/townService";

const BusinessDirectoryListingFields = ({
  formErrors,
  usStates,
  content,
  adFree,
}) => {
  const classes = useStyles();
  const { setProperty } = useSubmitableContentForm();
  const [chosenStateId, setChosenStateId] = useState();

  const shouldMarkError = (prop) => {
    let validation;

    validation = MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING.find(
      (mandatory) => mandatory.key === prop
    );
    if (formErrors.includes(validation.error)) {
      return true;
    }
  };

  const handleStateChoice = (choice) => {
    setChosenStateId(choice);
  };

  useEffect(() => {
    setProperty(STATE, chosenStateId);
    setProperty(AD_FREE, adFree);
  }, [chosenStateId, adFree]);

  useEffect(() => {
    if (content?.id) {
      setProperty(CONTENT_ID, content?.id);
      setProperty(BUSINESS_NAME, content?.name);
      setProperty(BUSINESS_ADDRESS, content?.address);
      setProperty(BUSINESS_ADDRESS2, content?.address2);
      setProperty(CITY, content?.city);
      setProperty(STATE, content?.state?.id);
      setProperty(ZIP_CODE, content?.zip_code);
      setProperty(PHONE_NUMBER, content?.phone);
      setProperty(FAX, content?.fax);
      setProperty(EMAIL, content?.email);
      setProperty(CI_WEBSITE, content?.website);
      setProperty(CI_FACEBOOK, content?.ci_facebook);
      setProperty(CI_TWITTER, content?.ci_twitter);
      setProperty(CI_NAME_ADMIN, content?.ci_name_admin);
      setProperty(CI_PHONE_ADMIN, content?.ci_phone_admin);
      setProperty(CI_PHONE_ADMIN, content?.ci_phone_admin);
      setProperty(ORGANIZATION_TYPE, content?.organization_type);
      setProperty(FACEBOOK_URL, content?.facebook_client_url);
      setProperty(FACEBOOK_EXCERPT, content?.excerpt);
      setProperty(POST_ON_FACEBOOK, content?.post_on_facebook);
      setProperty(FACEBOOK_TOWN_IDS, content?.town_facebook_ids);
      setChosenStateId(content?.state);
    }
  }, [content]);

  return (
    <>
      <div>
        <TextField
          label={labels.BUSINESS_NAME}
          onChange={(e) => setProperty(BUSINESS_NAME, e.target.value)}
          error={shouldMarkError(BUSINESS_NAME)}
          required
          defaultValue={content?.name}
        />
      </div>
      <div className={classes.regular_text_field}>
        <Typography
          level={
            screenWidthIsLowerThan(900) ? "medium_17_px" : "h4_sub_header_4"
          }
          color={GRAY}
          className={classes.display_block}
        >
          {labels.EXPIRE_BUSINESS_DIRECTORIES}
        </Typography>
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.BUSINESS_ADDRESS}
          onChange={(e) => setProperty(BUSINESS_ADDRESS, e.target.value)}
          error={shouldMarkError(BUSINESS_ADDRESS)}
          defaultValue={content?.address}
          required
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.ADDRESS_OPTIONAL}
          onChange={(e) => setProperty(BUSINESS_ADDRESS2, e.target.value)}
          defaultValue={content?.address2}
        />
      </div>
      <div className={classes.multiple_textfields_container}>
        <div className={classes.multiple_textfields}>
          <TextField
            label={labels.CITY}
            onChange={(e) => setProperty(CITY, e.target.value)}
            error={shouldMarkError(CITY)}
            defaultValue={content?.city}
            required
          />
        </div>
        <div className={classes.multiple_textfields}>
          <Dropdown
            label={labels.STATE}
            items={getDropdownChoices(usStates, handleStateChoice, false)}
            getChosenOptionId={handleStateChoice}
            error={shouldMarkError(STATE)}
            required
            typographyLevel={
              screenWidthIsLowerThan(900) ? "medium_17_px" : "h4"
            }
            defaultValue={content?.state}
            shouldPreselectFirstOption={false}
          />
        </div>
      </div>
      <div className={classes.multiple_textfields_container}>
        <div className={classes.multiple_textfields}>
          <TextField
            label={labels.ZIP_CODE}
            onChange={(e) => setProperty(ZIP_CODE, e.target.value)}
            error={shouldMarkError(ZIP_CODE)}
            defaultValue={content?.zip_code}
            required
          />
        </div>
        <div className={classes.multiple_textfields}>
          <TextField
            label={labels.PHONE_NUMBER}
            onChange={(e) => setProperty(PHONE_NUMBER, e.target.value)}
            defaultValue={content?.phone}
          />
        </div>
      </div>
      <div className={classes.multiple_textfields_container}>
        <div className={classes.multiple_textfields}>
          <TextField
            label={labels.FAX}
            onChange={(e) => setProperty(FAX, e.target.value)}
            defaultValue={content?.fax}
          />
        </div>
        <div className={classes.multiple_textfields}>
          <TextField
            label={labels.EMAIL}
            onChange={(e) => setProperty(EMAIL, e.target.value)}
            defaultValue={content?.email}
          />
        </div>
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.WEBSITE}
          onChange={(e) => setProperty(CI_WEBSITE, e.target.value)}
          defaultValue={content?.website}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.FACEBOOK}
          onChange={(e) => setProperty(CI_FACEBOOK, e.target.value)}
          labelColor={FACEBOOK_BLUE}
          defaultValue={content?.ci_facebook}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.TWITTER}
          onChange={(e) => setProperty(CI_TWITTER, e.target.value)}
          labelColor={TWITTER_BLUE}
          defaultValue={content?.ci_twitter}
        />
      </div>
      <div className={classes.text_container}>
        <Typography
          level="h3_sub_header"
          color={GREEN}
          bold
          className={classes.contact_information_label}
        >
          {labels.CONTACT_INFORMATION}
        </Typography>
        <Typography level="h4_sub_header_4" color={GREEN}>
          {labels.CONTENT_WILL_BE_SEEN_BY_ADMIN}
        </Typography>
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.CONTACT_PERSON}
          onChange={(e) => setProperty(CI_NAME_ADMIN, e.target.value)}
          error={shouldMarkError(CI_NAME_ADMIN)}
          defaultValue={content?.ci_name_admin}
          required
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          label={labels.PHONE_NUMBER}
          onChange={(e) => setProperty(CI_PHONE_ADMIN, e.target.value)}
          error={shouldMarkError(CI_PHONE_ADMIN)}
          defaultValue={content?.ci_phone_admin}
          required
        />
      </div>
    </>
  );
};

BusinessDirectoryListingFields.propTypes = {
  formErrors: PropTypes.arrayOf(PropTypes.string),
  usStates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  content: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    zip_code: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    ci_facebook: PropTypes.string,
    ci_twitter: PropTypes.string,
    ci_name_admin: PropTypes.string,
    ci_phone_admin: PropTypes.string,
    categories: PropTypes.array,
    main_town_ids: PropTypes.array,
    organization_type: PropTypes.string,
    facebook_client_url: PropTypes.string,
    excerpt: PropTypes.string,
    post_on_facebook: PropTypes.bool,
    town_facebook_ids: PropTypes.array,
  }),
};

export default BusinessDirectoryListingFields;
