import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import Typography from "../../../../common/Typography/Typography";
import { GRAY, GREEN_HEX } from "../../diyConstants";
import Spinner from "../../../../common/Spinner/Spinner";
import { useStyles } from "./ShoppingCart.style";
import ShoppingList from "../ShoppingList/ShoppingList";
import Actions from "../Actions/Actions";
import withConfig from "../../../../utils/withConfig";
import RadioButton from "../../../../common/RadioButton/RadioButton";
import { replaceInHtmlText } from "../../../../utils/helper";
import { useMasthead } from "../../../../hooks/useMasthead";
import clsx from "clsx";
import {
  useTownTheme,
  withTownThemeContextProvider,
} from "../../../../context/TownThemeContext";
import Checkbox from "../../../../common/Checkbox/Checkbox";

const LEGAL_NOTICE = "legal_notice";

const ShoppingCart = ({
  isMobileView,
  townsSelected,
  formType,
  loadingCheckout,
  setLoadingCheckout,
  showFacebookBlock,
  isEditForm,
  setOpenMobileShoppingCart,
  itemCountForEventDraft,
  town,
}) => {
  const [isRadioButtonChecked, setIsRadioButtonChecked] = useState(false);
  const [isLegalNoticeChecked, setIsLegalNoticeChecked] = useState(false);
  const classes = useStyles();
  const { sticky } = useMasthead();
  const { themeTexts, setTownSlug } = useTownTheme();

  const disableRadioButton = () => {
    if (townsSelected.length > 0) {
      return false;
    } else if (townsSelected.length <= 0) {
      return true;
    }
    return true;
  };
  useEffect(() => {
    if (town) {
      setTownSlug(town?.slug);
    }

    return () => {
      setTownSlug();
    };
  }, [town]);

  const townsCount = () => {
    if (townsSelected.length > 0) {
      return ` (${townsSelected.length})`;
    }
    return "";
  };

  const AgreementClause = ({ label }) => {
    const replacements = [
      {
        toReplace: "Terms of Use",
        toReplaceWith: `<a href="${withConfig(
          "TAPINTO_TERMS_OF_USE"
        )}" target="_blank">Terms Of Use</a>`,
      },
      {
        toReplace: "Privacy Policy",
        toReplaceWith: `<a href="${withConfig(
          "PRIVACY_POLICY"
        )}" target="_blank">Privacy Policy</a>`,
      },
      {
        toReplace: "Advertising Policy",
        toReplaceWith: `<a href="${withConfig(
          "ADVERTISING_POLICY"
        )}" target="_blank">Advertising Policy</a>`,
      },
      {
        toReplace: ". I",
        toReplaceWith: ". <br />I",
      },
    ];

    if (town?.under_license_contract) {
      replacements.push({
        toReplace: "TAPinto.net",
        toReplaceWith: town?.name,
      });
    }

    return replaceInHtmlText(
      label,
      replacements,
      classes.agreement_clause_text
    );
  };

  return (
    <div
      className={clsx(
        isMobileView ? classes.popup_cart : classes.fixed_cart,
        sticky && classes.with_top_margin
      )}
    >
      <Typography level="paragraph_2" bold>
        {labels.SHOPPING_CART}
        {townsCount()}
      </Typography>

      <ShoppingList
        townsSelected={townsSelected}
        formType={formType}
        showFacebookBlock={showFacebookBlock}
        itemCountForEventDraft={itemCountForEventDraft}
        town={town}
      />

      <div className={classes.disclaimer_container}>
        {formType === LEGAL_NOTICE ? (
          <AgreementClause
            label={labels.SHOPPING_CART_DISCLAIMER_FOR_LEGAL_NOTICES}
          />
        ) : (
          <Typography level="medium_10_px" color={GRAY}>
            {labels.SHOPPING_CART_DISCLAIMER}
          </Typography>
        )}
      </div>

      <div className={classes.agreement_clause_container}>
        <div className={classes.agreement_clause_radiobutton_container}>
          <RadioButton
            disabled={disableRadioButton()}
            onClick={() => setIsRadioButtonChecked(!isRadioButtonChecked)}
            checked={isRadioButtonChecked}
          />
        </div>
        {!themeTexts?.custom_shopping_agreement ? (
          <AgreementClause label={labels.AGREEMENT_CLAUSE} />
        ) : (
          <div
            className={classes.custom_terms_agreement}
            dangerouslySetInnerHTML={{
              __html: themeTexts.custom_shopping_agreement,
            }}
          />
        )}
      </div>
      {formType === LEGAL_NOTICE && (
        <div className={classes.legal_notice_terms_container}>
          <Checkbox
            checked={isLegalNoticeChecked}
            onChange={() => setIsLegalNoticeChecked(!isLegalNoticeChecked)}
            checkboxColor={GREEN_HEX}
          />
          <Typography
            level="medium_10_px"
            className={classes.legal_notice_terms}
          >
            {labels.LEGAL_NOTICE_TERMS}
          </Typography>
        </div>
      )}

      <Actions
        setLoadingCheckout={setLoadingCheckout}
        isEditForm={isEditForm}
        formType={formType}
        setOpenMobileShoppingSummary={setOpenMobileShoppingCart}
        isRadioButtonChecked={isRadioButtonChecked}
        townsSelected={townsSelected}
        legalNoticeTermsChecked={isLegalNoticeChecked}
      />

      {loadingCheckout && (
        <Spinner
          withLoadingOverlay={true}
          loadingOverlayClassName={classes.spinner_overlay}
          className={classes.spinner}
          withFadingLabels={true}
        />
      )}
    </div>
  );
};

ShoppingCart.propTypes = {
  isMobileView: PropTypes.bool,
  townsSelected: PropTypes.array,
  formType: PropTypes.string,
  loadingCheckout: PropTypes.bool,
  setLoadingCheckout: PropTypes.func,
  showFacebookBlock: PropTypes.bool,
  isEditForm: PropTypes.bool,
  setOpenMobileShoppingCart: PropTypes.func,
  itemCountForEventDraft: PropTypes.number,
};

export default withTownThemeContextProvider(ShoppingCart);
