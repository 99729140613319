import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;

  return {
    container: {
      justifyContent: `center !important`,
      width: "100%",
      backgroundColor: `${palette.grayVariation.grayForBackground} !important`,
      borderRadius: `${theme.spacing(1)}px !important`,
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        gap: theme.spacing(2),
      },
    },
    title: {
      fontSize: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
      fontFamily: theme.fontFamily.primary,
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.spacing(2)}px !important`,
      },
    },
    optionRow: {
      display: "flex",
      alignItems: "center",
      gridTemplateColumns: "40px auto 120px",
      padding: `${theme.spacing(2)}px 0`,
      "&:last-child": {
        borderBottom: "none",
      },
    },
    checkboxWrapper: {
      display: "flex",
      alignItems: "center",
    },
    textWrapper: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: theme.spacing(1),
    },
    optionTitle: {
      fontSize: theme.spacing(2),
      fontFamily: theme.fontFamily.primary,
    },
    optionDescription: {
      fontSize: theme.spacing(1.75),
      color: palette.grayVariation.grayForSubLabel,
      fontFamily: theme.fontFamily.primary,
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.spacing(1.5)}px !important`,
      },
    },
    priceWrapper: {
      textAlign: "right",
      display: "grid",
    },
    optionPrice: {
      textAlign: "center",
    },
    priceDetails: {
      fontSize: theme.spacing(1.5),
      color: palette.grayVariation.grayForSubLabel,
      fontFamily: theme.fontFamily.primary,
      fontSize: theme.spacing(1),
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.spacing(1)}px !important`,
      },
    },
  };
});

export { useStyles };
