import cache from "../../utils/cache";
import { setFormErrors } from "../../utils/listeners";
import restClient from "../../utils/restClient";
import withConfig from "../../utils/withConfig";
import {
  HEADLINE,
  SUBHEADING,
  FACEBOOK_TOWN_IDS,
  PHOTO_IDS,
  CONTENT_BODY,
  MAIN_TOWN_IDS,
  AUTHORS_NAME,
  FACEBOOK_EXCERPT,
  FACEBOOK_URL,
  FORM_TYPE,
  POST_ON_FACEBOOK,
  MANDATORY_FIELDS,
  COLUMN_ID,
  CATEGORY_IDS,
  ORGANIZATION_TYPE,
  NON_PROFIT_CHECKBOX,
  MANDATORY_PHONE_NUMBER_ERROR,
  PHONE_NUMBER,
  LETTERS_TO_THE_EDITOR,
  FREELANCE,
  CANDIDATE_STATEMENTS,
  COLUMN,
  NEWS,
  EVENT,
  CATEGORIES,
  MANDATORY_FIELDS_FOR_EVENT,
  EVENT_NAME,
  ADDRESS,
  CITY,
  EVENT_DATE_STARTS,
  EVENT_START_TIME,
  STATE,
  EVENT_DATE_ENDS,
  EVENT_END_TIME,
  ZIPCODE,
  CI_NAME_ADMIN,
  CI_PHONE_ADMIN,
  CI_NAME,
  CI_PHONE,
  CI_EMAIL,
  CI_WEBSITE,
  CI_FACEBOOK,
  CI_TWITTER,
  CI_INSTAGRAM,
  ALL_DAY,
  EVENT_DATES,
  VIRTUAL_EVENT,
  VIRTUAL_EVENT_URL,
  VENUE,
  MILESTONE,
  BUSINESS_DIRECTORY_LISTING,
  CLASSIFIED,
  REAL_ESTATE_LISTING,
  OBITUARY,
  MANDATORY_FIELDS_FOR_MILESTONE,
  CONTACT_PERSON_PRIVATE,
  PHONE_NUMBER_PRIVATE,
  EMAIL,
  FREELANCE_EMAIL,
  ZIP_CODE,
  FAX,
  BUSINESS_NAME,
  BUSINESS_ADDRESS,
  BUSINESS_ADDRESS2,
  MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING,
  CONTACT_PERSON,
  WEBSITE,
  FACEBOOK,
  TWITTER,
  MANDATORY_FIELDS_FOR_CLASSIFIED,
  BATHROOMS,
  BEDROOMS,
  LOT_SIZE,
  PRICE,
  MLS_NUMBER,
  MLS_URL,
  LISTING_URL,
  REALTOR_NAME,
  AGENCY_NAME,
  AGENCY_PHONE_NUMBER,
  MANDATORY_FIELDS_FOR_REAL_ESTATE,
  SOLD,
  UNDER_CONTRACT,
  VIDEOS,
  NAME_OF_DECEASED,
  DATE_OF_BIRTH,
  DATE_OF_DEATH,
  MANDATORY_FIELDS_FOR_OBITUARY,
  VISITATION_INFO,
  SERVICE_INFO,
  BURIAL_INFO,
  CREMATION_INFO,
  MANDATORY_FIELDS_FOR_COLUMN,
  CONTENT_ID,
  MANDATORY_DRAFT_ARTICLE_FIELDS,
  TOWN_OF_RESIDENCY,
  LTE_QUESTION,
  MANDATORY_TOWN_OF_RESIDENCY_ERROR,
  MANDATORY_FIELDS_FOR_EVENT_PREVIEW,
  MANDATORY_FIELDS_FOR_OBITUARY_PREVIEW,
  MORE_THAN_TWENTY_TOWNS_ERROR,
  REAL_ESTATE_ID,
  REMOVABLE_SERVICES_ID,
  CREDITS_TEXT,
  CAPTION_TEXT,
  ALT_TEXT,
  VIDEOS_TO_REMOVE,
  MANDATORY_NON_PROFIT_CHECKBOX,
  AUTHORS_NAME_CANNOT_BE_ANONYMOUS_ERROR,
  MANDATORY_FIELDS_FOR_PREVIEW,
  MANDATORY_FIELDS_FOR_REAL_ESTATE_PREVIEW,
  PRESS_RELEASE,
  CLASSIFIEDS,
  MANDATORY_FIELDS_FOR_CONTENT_DRAFT,
  MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING_DRAFT,
  BUSINESS_LISTINGS,
  MANDATORY_FIELDS_FOR_EVENT_DRAFT,
  DATES_TO_REMOVE,
  MANDATORY_FIELDS_FOR_OBITUARY_DRAFT,
  FOR_RENT,
  FOR_SALE,
  TOWN,
  MANDATORY_TOWN_ERROR,
  LEGAL_NOTICE,
  LEGAL_ENTITY_ID,
  SCHEDULED_DATES,
  LEGAL_NOTICE_NAME,
  MANDATORY_FIELDS_FOR_LEGAL_NOTICE,
  IS_SAME_CONTACT_INFO,
  SCHEDULED_HOUR,
  SHOW_ENTITY_LOGO,
  LEGAL_NOTICE_DOCUMENTS,
  LEGAL_NOTICE_DOCUMENT_IDS,
  AD_FREE,
  OBITUARY_DOCUMENTS,
  PHOTO_REQUIRED,
} from "./diyConstants";
import { format, add } from "date-fns";

const TOMORROW = add(new Date(), { days: 1 });
const TOMORROW_FORMATTED = format(TOMORROW, "MM/dd/yyyy");

const PUT = "put";
const POST = "post";

const useSubmitableContentForm = () => {
  const setProperty = (name, value) => {
    cache.set(name, value);
  };

  const setPhotoIdsByContent = (contentType) => {
    const photoIdsNode = document.getElementsByName(
      `${contentType}[photo_ids][]`
    );
    const photoIds = [];
    for (let i = 0; i < photoIdsNode.length; i++) {
      photoIds.push(parseInt(photoIdsNode[i].value));
    }
    cache.set(PHOTO_IDS, photoIds);
  };

  const getPhotosWithBulkData = (content) => {
    const bulkData = {
      captionCheckbox: document.getElementsByName(
        `${content}[copy_caption]`
      )[0],
      captionText: document.getElementsByName(
        `${content}[text_to_copy_caption]`
      )[0],
      creditsCheckbox: document.getElementsByName(
        `${content}[copy_credits]`
      )[0],
      creditsText: document.getElementsByName(
        `${content}[text_to_copy_credits]`
      )[0],
      altTextCheckbox: document.getElementsByName(
        `${content}[copy_alt_text]`
      )[0],
      altText: document.getElementsByName(
        `${content}[text_to_copy_alt_text]`
      )[0],
    };

    cache.set(CREDITS_TEXT, bulkData.creditsText?.value);
    cache.set(CAPTION_TEXT, bulkData.captionText?.value);
    cache.set(ALT_TEXT, bulkData.altText?.value);
  };

  const setContentBody = () => {
    const domEditableElement = document.querySelector(".ck-editor__editable");
    const editorInstance = domEditableElement.ckeditorInstance;
    console.log({ editorInstance: editorInstance.getData() });
    cache.set(CONTENT_BODY, editorInstance.getData());
  };

  const validateNonProfitCheckbox = (organizationType, errors) => {
    if (organizationType === "nonprofit" && !cache.get(NON_PROFIT_CHECKBOX)) {
      errors.push(MANDATORY_NON_PROFIT_CHECKBOX);
    }
  };

  const validateArticle = (showPreview = false) => {
    const errors = [];

    if (showPreview) {
      for (let i = 0; i < MANDATORY_FIELDS_FOR_PREVIEW.length; i++) {
        if (
          !!cache.get(MANDATORY_FIELDS_FOR_PREVIEW[i].key) === false ||
          (MANDATORY_FIELDS_FOR_PREVIEW[i].key === MAIN_TOWN_IDS &&
            cache.get(MANDATORY_FIELDS_FOR_PREVIEW[i].key).length === 0)
        ) {
          errors.push(MANDATORY_FIELDS_FOR_PREVIEW[i].error);
        }
      }
    } else {
      validateNonProfitCheckbox(cache.get(ORGANIZATION_TYPE), errors);

      if (cache.get(FORM_TYPE) === "column") {
        for (let i = 0; i < MANDATORY_FIELDS_FOR_COLUMN.length; i++) {
          if (
            !!cache.get(MANDATORY_FIELDS_FOR_COLUMN[i].key) === false ||
            cache.get(MANDATORY_FIELDS_FOR_COLUMN[i].key).length === 0
          ) {
            errors.push(MANDATORY_FIELDS_FOR_COLUMN[i].error);
          }
        }
      } else if (cache.get(FORM_TYPE) === "letters-to-the-editor") {
        if (
          cache.get(AUTHORS_NAME) &&
          cache.get(AUTHORS_NAME).toLowerCase().includes("anonymous")
        ) {
          errors.push(AUTHORS_NAME_CANNOT_BE_ANONYMOUS_ERROR);
        }

        if (!!cache.get(PHONE_NUMBER) === false) {
          errors.push(MANDATORY_PHONE_NUMBER_ERROR);
        }

        if (!!cache.get(TOWN_OF_RESIDENCY) === false) {
          errors.push(MANDATORY_TOWN_OF_RESIDENCY_ERROR);
        }

        if (cache.get(MAIN_TOWN_IDS).length === 0) {
          errors.push(MANDATORY_TOWN_ERROR);
        }
      } else {
        for (let i = 0; i < MANDATORY_FIELDS.length; i++) {
          if (
            !!cache.get(MANDATORY_FIELDS[i].key) === false ||
            (MANDATORY_FIELDS[i].key === MAIN_TOWN_IDS &&
              cache.get(MANDATORY_FIELDS[i].key).length === 0)
          ) {
            errors.push(MANDATORY_FIELDS[i].error);
          }
        }
      }
    }

    setFormErrors(errors);
    return errors;
  };

  const validateDraftArticle = (type = undefined) => {
    const errors = [];
    switch (type) {
      case COLUMN:
        for (let i = 0; i < MANDATORY_FIELDS_FOR_CONTENT_DRAFT.length; i++) {
          if (
            !!cache.get(MANDATORY_FIELDS_FOR_CONTENT_DRAFT[i].key) === false ||
            cache.get(MANDATORY_FIELDS_FOR_CONTENT_DRAFT[i].key).length === 0
          ) {
            errors.push(MANDATORY_FIELDS_FOR_CONTENT_DRAFT[i].error);
          }
        }
        break;
      default:
        for (let i = 0; i < MANDATORY_DRAFT_ARTICLE_FIELDS.length; i++) {
          if (
            !!cache.get(MANDATORY_DRAFT_ARTICLE_FIELDS[i].key) === false ||
            (MANDATORY_DRAFT_ARTICLE_FIELDS[i].key === MAIN_TOWN_IDS &&
              cache.get(MANDATORY_DRAFT_ARTICLE_FIELDS[i].key).length === 0)
          ) {
            errors.push(MANDATORY_DRAFT_ARTICLE_FIELDS[i].error);
          }
        }
        break;
    }

    setFormErrors(errors);
    return errors;
  };

  const validateRegularContent = (
    LIST_OF_MANDATORY_FIELDS,
    isEditForm = false,
    isDraftForm = false
  ) => {
    const errors = [];

    if (!isEditForm) {
      validateNonProfitCheckbox(cache.get(ORGANIZATION_TYPE), errors);
    }

    for (let i = 0; i < LIST_OF_MANDATORY_FIELDS.length; i++) {
      if (
        !!cache.get(LIST_OF_MANDATORY_FIELDS[i].key) === false ||
        (LIST_OF_MANDATORY_FIELDS[i].key === MAIN_TOWN_IDS &&
          cache.get(LIST_OF_MANDATORY_FIELDS[i].key).length === 0)
      ) {
        errors.push(LIST_OF_MANDATORY_FIELDS[i].error);
      }
    }

    if (cache.get(FORM_TYPE) === "milestone" && !isDraftForm) {
      if (!!cache.get(TOWN_OF_RESIDENCY) === false) {
        errors.push(MANDATORY_TOWN_OF_RESIDENCY_ERROR);
      }
      if (cache.get(MAIN_TOWN_IDS).length > 20) {
        errors.push(MORE_THAN_TWENTY_TOWNS_ERROR);
      }
    }

    if (cache.get(FORM_TYPE) === "letters-to-the-editor") {
      if (!!cache.get(PHONE_NUMBER) === false) {
        errors.push(MANDATORY_PHONE_NUMBER_ERROR);
      }

      if (!!cache.get(TOWN_OF_RESIDENCY) === false) {
        errors.push(MANDATORY_TOWN_OF_RESIDENCY_ERROR);
      }
    }

    setFormErrors(errors);
    return errors;
  };

  const getCategoryId = () => {
    switch (cache.get(FORM_TYPE)) {
      case NEWS:
        return CATEGORY_IDS.news;
      case CANDIDATE_STATEMENTS:
        return CATEGORY_IDS.candidate_statements;
      case LETTERS_TO_THE_EDITOR:
        return CATEGORY_IDS.letters_to_the_editor;
      case FREELANCE:
        return CATEGORY_IDS.freelance;
      case COLUMN:
        return "";
      case EVENT:
      case MILESTONE:
      case BUSINESS_DIRECTORY_LISTING:
      case CLASSIFIED:
      case REAL_ESTATE_LISTING:
        return cache.get(CATEGORIES);
      default:
        return "";
    }
  };

  const getOrganizationType = () => {
    const organizationType = cache.get(ORGANIZATION_TYPE);
    if (organizationType) {
      return organizationType;
    }
    return "";
  };

  const getVideosContent = () => {
    const videoUrlNodes = document.getElementsByName(
      `content[video_links][][url]`
    );
    const videoCaptionNodes = document.getElementsByName(
      `content[video_links][][caption]`
    );
    const videoCreditsNodes = document.getElementsByName(
      `content[video_links][][credits]`
    );
    const videoIdsNodes = document.getElementsByName(
      `content[video_links][][id]`
    );
    const videos = [];

    const isValidVideoUrl = (url) => {
      const youtubeRegex =
        /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?(.*&)?v=|youtu\.be\/)/;

      const vimeoRegex = /^(https?:\/\/)?(www\.)?vimeo\.com\/\d+/;

      return youtubeRegex.test(url) || vimeoRegex.test(url);
    };
    Array.from(videoUrlNodes).forEach((node) => {
      const url = node.value.trim();
      if (url !== "" && !isValidVideoUrl(url)) {
        alert(`Please upload a valid video URL.`);
      }
    });

    for (let i = 0; i < videoUrlNodes.length; i++) {
      const videoLink = {
        url: videoUrlNodes[i]?.value || "",
        caption: videoCaptionNodes[i]?.value || "",
        credits: videoCreditsNodes[i]?.value || "",
        id: videoIdsNodes[i]?.value || "",
      };
      videos.push(videoLink);
    }

    cache.set(VIDEOS, videos);
  };

  const videosToRemove = () => {
    const videoIds = document.getElementsByName("videos_to_remove");
    cache.set(VIDEOS_TO_REMOVE, videoIds[0]?.value);
  };

  const buildContentPayload = () => {
    const formType = cache.get(FORM_TYPE);
    const category_ids = getCategoryId();
    const organizationType = getOrganizationType();
    getVideosContent();
    videosToRemove();

    switch (formType) {
      case NEWS:
      case CANDIDATE_STATEMENTS:
      case LETTERS_TO_THE_EDITOR:
      case FREELANCE:
      case COLUMN:
        setPhotoIdsByContent("article");
        getPhotosWithBulkData("article");
        return {
          article: {
            category_ids: category_ids,
            column_id: cache.get(COLUMN_ID),
            organization_type: organizationType,
            name: cache.get(HEADLINE),
            subheading: cache.get(SUBHEADING),
            byline: cache.get(AUTHORS_NAME),
            town_ids: cache.get(MAIN_TOWN_IDS),
            post_on_facebook: cache.get(POST_ON_FACEBOOK),
            town_facebook_ids: cache.get(FACEBOOK_TOWN_IDS),
            facebook_client_url: cache.get(FACEBOOK_URL),
            excerpt: cache.get(FACEBOOK_EXCERPT),
            free_facebook: false,
            ad_free: cache.get(AD_FREE),
            photo_ids: cache.get(PHOTO_IDS),
            body: cache.get(CONTENT_BODY),
            terms_of_use: 1,
            agree_to_license: 1,
            phone_number: cache.get(PHONE_NUMBER),
            id: cache.get(CONTENT_ID),
            town_of_residency: cache.get(TOWN_OF_RESIDENCY),
            lte_question: cache.get(LTE_QUESTION),
            text_to_copy_credits: cache.get(CREDITS_TEXT),
            text_to_copy_caption: cache.get(CAPTION_TEXT),
            text_to_copy_alt_text: cache.get(ALT_TEXT),
            freelance_email: cache.get(FREELANCE_EMAIL),
          },
          content: {
            video_links: cache.get(VIDEOS),
          },
          videos_to_remove: cache.get(VIDEOS_TO_REMOVE),
        };
      case EVENT:
        setPhotoIdsByContent("event");
        getPhotosWithBulkData("event");
        return {
          event: {
            organization_type: organizationType,
            name: cache.get(EVENT_NAME),
            starts: cache.get(EVENT_DATE_STARTS) || TOMORROW_FORMATTED,
            ends: cache.get(EVENT_DATE_ENDS) || TOMORROW_FORMATTED,
            start_time: cache.get(EVENT_START_TIME),
            end_time: cache.get(EVENT_END_TIME),
            all_day: cache.get(ALL_DAY) ? "1" : "0",
            event_dates_attributes: cache.get(EVENT_DATES),
            virtual_event: cache.get(VIRTUAL_EVENT) ? "1" : "0",
            virtual_event_url: cache.get(VIRTUAL_EVENT_URL),
            address: cache.get(ADDRESS),
            venue: cache.get(VENUE),
            city: cache.get(CITY),
            state: cache.get(STATE),
            zip_code: cache.get(ZIPCODE),
            ci_name_admin: cache.get(CI_NAME_ADMIN),
            ci_phone_admin: cache.get(CI_PHONE_ADMIN),
            ci_name: cache.get(CI_NAME),
            ci_phone: cache.get(CI_PHONE),
            ci_email: cache.get(CI_EMAIL),
            ci_website: cache.get(CI_WEBSITE),
            ci_facebook: cache.get(CI_FACEBOOK),
            ci_twitter: cache.get(CI_TWITTER),
            ci_instagram: cache.get(CI_INSTAGRAM),
            description: cache.get(CONTENT_BODY),
            town_ids: cache.get(MAIN_TOWN_IDS),
            category_ids: category_ids,
            photo_ids: cache.get(PHOTO_IDS),
            post_on_facebook: cache.get(POST_ON_FACEBOOK),
            town_facebook_ids: cache.get(FACEBOOK_TOWN_IDS),
            facebook_client_url: cache.get(FACEBOOK_URL),
            excerpt: cache.get(FACEBOOK_EXCERPT),
            free_facebook: false,
            ad_free: cache.get(AD_FREE),
            terms_of_use: 1,
            agree_to_license: 1,
            r_starts_l: cache.get(EVENT_DATE_STARTS),
            text_to_copy_credits: cache.get(CREDITS_TEXT),
            text_to_copy_caption: cache.get(CAPTION_TEXT),
            text_to_copy_alt_text: cache.get(ALT_TEXT),
            id: cache.get(CONTENT_ID),
            dates_to_remove: cache.get(DATES_TO_REMOVE),
            copy_contact_info: cache.get(IS_SAME_CONTACT_INFO),
          },
          content: {
            video_links: cache.get(VIDEOS),
          },
          videos_to_remove: cache.get(VIDEOS_TO_REMOVE),
        };
      case MILESTONE:
        setPhotoIdsByContent("milestone");
        getPhotosWithBulkData("milestone");
        return {
          milestone: {
            organization_type: organizationType,
            name: cache.get(HEADLINE),
            town_ids: cache.get(MAIN_TOWN_IDS),
            town_facebook_ids: cache.get(FACEBOOK_TOWN_IDS),
            post_on_facebook: cache.get(POST_ON_FACEBOOK),
            facebook_client_url: cache.get(FACEBOOK_URL),
            excerpt: cache.get(FACEBOOK_EXCERPT),
            photo_ids: cache.get(PHOTO_IDS),
            body: cache.get(CONTENT_BODY),
            contact_person: cache.get(CONTACT_PERSON_PRIVATE),
            contact_phone: cache.get(PHONE_NUMBER_PRIVATE),
            contact_email: cache.get(EMAIL),
            milestone_category_id: category_ids[0],
            terms_of_use: 1,
            agree_to_license: 1,
            free_facebook: false,
            town_of_residency: cache.get(TOWN_OF_RESIDENCY),
            text_to_copy_credits: cache.get(CREDITS_TEXT),
            text_to_copy_caption: cache.get(CAPTION_TEXT),
            text_to_copy_alt_text: cache.get(ALT_TEXT),
            id: cache.get(CONTENT_ID),
          },
          content: {
            video_links: cache.get(VIDEOS),
          },
          videos_to_remove: cache.get(VIDEOS_TO_REMOVE),
        };
      case BUSINESS_DIRECTORY_LISTING:
        setPhotoIdsByContent("business_listing");
        getPhotosWithBulkData("business_listing");
        return {
          business_listing: {
            organization_type: organizationType,
            name: cache.get(BUSINESS_NAME),
            town_ids: cache.get(MAIN_TOWN_IDS),
            category_ids: category_ids,
            post_on_facebook: cache.get(POST_ON_FACEBOOK),
            town_facebook_ids: cache.get(FACEBOOK_TOWN_IDS),
            facebook_client_url: cache.get(FACEBOOK_URL),
            excerpt: cache.get(FACEBOOK_EXCERPT),
            free_facebook: false,
            photo: undefined,
            photo_cache: undefined,
            photo_ids: cache.get(PHOTO_IDS),
            address: cache.get(BUSINESS_ADDRESS),
            address2: cache.get(BUSINESS_ADDRESS2),
            city: cache.get(CITY),
            state: cache.get(STATE),
            zip_code: cache.get(ZIP_CODE),
            phone: cache.get(PHONE_NUMBER),
            fax: cache.get(FAX),
            email: cache.get(EMAIL),
            website: cache.get(CI_WEBSITE),
            ci_facebook: cache.get(CI_FACEBOOK),
            ci_twitter: cache.get(CI_TWITTER),
            ci_name_admin: cache.get(CI_NAME_ADMIN),
            ci_phone_admin: cache.get(CI_PHONE_ADMIN),
            description: cache.get(CONTENT_BODY),
            terms_of_use: 1,
            agree_to_license: 1,
            text_to_copy_credits: cache.get(CREDITS_TEXT),
            text_to_copy_caption: cache.get(CAPTION_TEXT),
            text_to_copy_alt_text: cache.get(ALT_TEXT),
            id: cache.get(CONTENT_ID),
          },
          content: {
            video_links: cache.get(VIDEOS),
          },
          videos_to_remove: cache.get(VIDEOS_TO_REMOVE),
        };
      case CLASSIFIED:
        setPhotoIdsByContent("classified");
        getPhotosWithBulkData("classified");

        return {
          classified: {
            organization_type: organizationType,
            name: cache.get(HEADLINE),
            category_ids: category_ids,
            contact_person_admin: cache.get(CONTACT_PERSON_PRIVATE),
            phone_admin: cache.get(PHONE_NUMBER_PRIVATE),
            contact_person: cache.get(CONTACT_PERSON),
            phone: cache.get(PHONE_NUMBER),
            email: cache.get(EMAIL),
            url: cache.get(WEBSITE),
            facebook: cache.get(FACEBOOK),
            twitter: cache.get(TWITTER),
            description: cache.get(CONTENT_BODY),
            post_on_facebook: cache.get(POST_ON_FACEBOOK),
            town_facebook_ids: cache.get(FACEBOOK_TOWN_IDS),
            facebook_client_url: cache.get(FACEBOOK_URL),
            excerpt: cache.get(FACEBOOK_EXCERPT),
            town_ids: cache.get(MAIN_TOWN_IDS),
            free_facebook: false,
            ad_free: cache.get(AD_FREE),
            photo_ids: cache.get(PHOTO_IDS),
            agree_to_license: 1,
            terms_of_use: 1,
            text_to_copy_credits: cache.get(CREDITS_TEXT),
            text_to_copy_caption: cache.get(CAPTION_TEXT),
            text_to_copy_alt_text: cache.get(ALT_TEXT),
            id: cache.get(CONTENT_ID),
          },
          content: {
            video_links: cache.get(VIDEOS),
          },
          videos_to_remove: cache.get(VIDEOS_TO_REMOVE),
        };
      case OBITUARY:
        setPhotoIdsByContent("obituary");
        getPhotosWithBulkData("obituary");
        return {
          obituary: {
            name: cache.get(NAME_OF_DECEASED),
            date_of_birth: cache.get(DATE_OF_BIRTH),
            date_of_death: cache.get(DATE_OF_DEATH),
            obituary_service_visitations_attributes: cache.get(VISITATION_INFO),
            obituary_service_services_attributes: cache.get(SERVICE_INFO),
            obituary_service_burial_attributes: cache.get(BURIAL_INFO)[0],
            obituary_service_cremation_attributes: cache.get(CREMATION_INFO)[0],
            description: cache.get(CONTENT_BODY),
            main_town_ids: cache.get(MAIN_TOWN_IDS),
            photo_ids: cache.get(PHOTO_IDS),
            premium: true,
            free_facebook: false,
            post_on_facebook: cache.get(POST_ON_FACEBOOK),
            town_facebook_ids: cache.get(FACEBOOK_TOWN_IDS),
            facebook_client_url: cache.get(FACEBOOK_URL),
            excerpt: cache.get(FACEBOOK_EXCERPT),
            text_to_copy_credits: cache.get(CREDITS_TEXT),
            text_to_copy_caption: cache.get(CAPTION_TEXT),
            text_to_copy_alt_text: cache.get(ALT_TEXT),
            id: cache.get(CONTENT_ID),
          },
          removable_services_id: cache.get(REMOVABLE_SERVICES_ID),
          content: {
            video_links: cache.get(VIDEOS),
            obituary_documents: cache.get(OBITUARY_DOCUMENTS),
          },
          videos_to_remove: cache.get(VIDEOS_TO_REMOVE),
        };
      case LEGAL_NOTICE:
        setPhotoIdsByContent("legal_notice");
        getPhotosWithBulkData("legal_notice");
        return {
          legal_notice: {
            show_entity_logo: cache.get(SHOW_ENTITY_LOGO),
            name: cache.get(LEGAL_NOTICE_NAME),
            legal_notice_entity_id: cache.get(LEGAL_ENTITY_ID),
            scheduled_dates: cache.get(SCHEDULED_DATES),
            scheduled_hour: cache.get(SCHEDULED_HOUR),
            description: cache.get(CONTENT_BODY),
            main_town_ids: cache.get(MAIN_TOWN_IDS),
            photo_ids: cache.get(PHOTO_IDS),
            legal_notice_document_ids: cache.get(LEGAL_NOTICE_DOCUMENT_IDS),
            text_to_copy_credits: cache.get(CREDITS_TEXT),
            text_to_copy_caption: cache.get(CAPTION_TEXT),
            text_to_copy_alt_text: cache.get(ALT_TEXT),
            id: cache.get(CONTENT_ID),
            photo_required: cache.get(PHOTO_IDS)
              ? true
              : cache.get(PHOTO_REQUIRED),
          },
          removable_services_id: cache.get(REMOVABLE_SERVICES_ID),
          content: {
            legal_notice_documents: cache.get(LEGAL_NOTICE_DOCUMENTS),
            video_links: cache.get(VIDEOS),
          },
          videos_to_remove: cache.get(VIDEOS_TO_REMOVE),
        };
      case REAL_ESTATE_LISTING:
        setPhotoIdsByContent("real_estate_listing");
        getPhotosWithBulkData("real_estate_listing");
        return {
          real_estate_listing: {
            name: cache.get(HEADLINE),
            byline: cache.get(AUTHORS_NAME),
            town_ids: cache.get(MAIN_TOWN_IDS),
            suggested_town_ids: [],
            category_ids: category_ids,
            post_on_facebook: cache.get(POST_ON_FACEBOOK),
            town_facebook_ids: cache.get(FACEBOOK_TOWN_IDS),
            facebook_client_url: cache.get(FACEBOOK_URL),
            excerpt: cache.get(FACEBOOK_EXCERPT),
            free_facebook: false,
            sold: cache.get(SOLD),
            under_contract: cache.get(UNDER_CONTRACT),
            address: cache.get(ADDRESS),
            city: cache.get(TOWN),
            state: cache.get(STATE),
            zip_code: cache.get(ZIPCODE),
            bedrooms: cache.get(BEDROOMS),
            bathrooms: cache.get(BATHROOMS),
            lot_size: cache.get(LOT_SIZE),
            price: cache.get(PRICE),
            mls_number: cache.get(MLS_NUMBER),
            mls_url: cache.get(MLS_URL),
            listing_url: cache.get(LISTING_URL),
            realtor_name: cache.get(REALTOR_NAME),
            phone: cache.get(PHONE_NUMBER),
            email: cache.get(EMAIL),
            website: cache.get(WEBSITE),
            agency_name: cache.get(AGENCY_NAME),
            agency_phone: cache.get(AGENCY_PHONE_NUMBER),
            description: cache.get(CONTENT_BODY),
            photo_ids: cache.get(PHOTO_IDS),
            terms_of_use: 1,
            agree_to_license: 1,
            text_to_copy_credits: cache.get(CREDITS_TEXT),
            text_to_copy_caption: cache.get(CAPTION_TEXT),
            text_to_copy_alt_text: cache.get(ALT_TEXT),
            id: cache.get(CONTENT_ID),
            for_rent: cache.get(FOR_RENT),
            for_sale: cache.get(FOR_SALE),
          },
          content: {
            video_links: cache.get(VIDEOS),
          },
          videos_to_remove: cache.get(VIDEOS_TO_REMOVE),
        };
      default:
        break;
    }
  };

  const submitByContentType = async (url, contentPayload, method = POST) => {
    try {
      let response;

      if (method === PUT) {
        response = await restClient.put(url, contentPayload);
      } else {
        response = await restClient.post(url, contentPayload);
      }

      console.log({ response });
      if (response?.data?.redirect_url) {
        const url = response?.data?.redirect_url;
        window.location.href = url;
        return;
      }

      if (response?.data?.errors) {
        setFormErrors(response?.data?.errors);
      }

      return response?.data;
    } catch (error) {
      console.error({ error });
      setFormErrors(error);
    }
  };

  const submitPreviewContent = async (url, contentPayload) => {
    try {
      const fakeForm = document.createElement("form");

      fakeForm.method = "post";
      fakeForm.action = url;

      const name = document.createElement("input");

      name.setAttribute("name", "previewPayload");
      name.setAttribute("value", JSON.stringify(contentPayload));

      fakeForm.appendChild(name);

      document.body.appendChild(fakeForm);

      fakeForm.target = "_blank";
      fakeForm.submit();

      document.body.removeChild(fakeForm);
    } catch (error) {
      console.error({ error });
      setFormErrors(error);
    }
  };

  const submitDraftForm = async () => {
    setContentBody();
    let validation = validateRegularContent(
      MANDATORY_FIELDS_FOR_CONTENT_DRAFT,
      false,
      true
    );
    let url;
    const contentPayload = buildContentPayload();
    let formType = cache.get(FORM_TYPE);
    switch (formType) {
      case NEWS:
      case LETTERS_TO_THE_EDITOR:
      case CANDIDATE_STATEMENTS:
        validation = validateDraftArticle();
        url = withConfig("ARTICLE_DRAFT_CREATION_URL");
      case COLUMN:
        validation = validateDraftArticle(COLUMN);
        url = withConfig("ARTICLE_DRAFT_CREATION_URL");
        break;
      case CLASSIFIEDS:
      case CLASSIFIED:
        url = withConfig("CLASSIFIED_DRAFT_CREATION_URL");
        break;
      case MILESTONE:
        url = withConfig("MILESTONE_DRAFT_CREATION_URL");
        break;
      case BUSINESS_DIRECTORY_LISTING:
        validation = validateRegularContent(
          MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING_DRAFT
        );
        url = withConfig("DIRECTORY_DRAFT_CREATION_URL");
        break;
      case REAL_ESTATE_LISTING:
        url = withConfig("REAL_ESTATE_DRAFT_CREATION_URL");
        break;
      case EVENT:
        validation = validateRegularContent(MANDATORY_FIELDS_FOR_EVENT_DRAFT);
        url = withConfig("EVENT_DRAFT_CREATION_URL");
        break;
      case OBITUARY:
        validation = validateRegularContent(
          MANDATORY_FIELDS_FOR_OBITUARY_DRAFT
        );
        url = withConfig("OBITUARY_DRAFT_CREATION_URL");
        break;
      case LEGAL_NOTICE:
        validation = validateRegularContent(MANDATORY_FIELDS_FOR_LEGAL_NOTICE);
        url = withConfig("LEGAL_NOTICE_DRAFT_CREATION_URL");
        break;
      default:
        break;
    }

    if (validation.length === 0) {
      try {
        const response = await restClient.post(url, contentPayload);
        if (response?.data?.errors) {
          setFormErrors(response?.data?.errors);
        }
        return response?.data;
      } catch (error) {
        return false;
      }
    }
  };

  const submitForm = async (showPreview) => {
    setContentBody();
    const formType = cache.get(FORM_TYPE);
    let validation;
    const contentPayload = buildContentPayload();
    let response = { errors: [""] };
    console.log({ contentPayload });

    switch (formType) {
      case EVENT:
        let mandatory_event_fields = MANDATORY_FIELDS_FOR_EVENT;
        if (showPreview) {
          mandatory_event_fields = MANDATORY_FIELDS_FOR_EVENT_PREVIEW;
        }

        validation = validateRegularContent(mandatory_event_fields);

        if (validation.length === 0) {
          if (showPreview) {
            response = await submitPreviewContent(
              withConfig("EVENT_PREVIEW_URL"),
              contentPayload
            );
          } else {
            response = await submitByContentType(
              withConfig("EVENT_CREATION_URL"),
              contentPayload
            );
          }
        }
        break;
      case NEWS:
      case LETTERS_TO_THE_EDITOR:
      case CANDIDATE_STATEMENTS:
      case COLUMN:
        validation = validateArticle(showPreview);
        if (validation.length === 0) {
          if (showPreview) {
            response = await submitPreviewContent(
              withConfig("ARTICLE_PREVIEW_URL"),
              contentPayload
            );
          } else {
            response = await submitByContentType(
              withConfig("ARTICLE_CREATION_URL"),
              contentPayload
            );
          }
        }

        break;
      case FREELANCE:
        validation = validateArticle(showPreview);
        if (validation.length === 0) {
          if (showPreview) {
            response = await submitPreviewContent(
              withConfig("ARTICLE_PREVIEW_URL"),
              contentPayload
            );
          } else {
            response = await submitByContentType(
              withConfig("FREELANCE_ARTICLE_CREATION_URL"),
              contentPayload
            );
          }
        }

        break;
      case MILESTONE:
        validation = validateRegularContent(MANDATORY_FIELDS_FOR_MILESTONE);
        if (validation.length === 0) {
          if (showPreview) {
            response = await submitPreviewContent(
              withConfig("MILESTONE_PREVIEW_URL"),
              contentPayload
            );
          } else {
            response = await submitByContentType(
              withConfig("MILESTONE_CREATION_URL"),
              contentPayload
            );
          }
        }
        break;
      case BUSINESS_DIRECTORY_LISTING:
        validation = validateRegularContent(
          MANDATORY_FIELDS_FOR_BUSINESS_DIRECTORY_LISTING,
          showPreview
        );
        if (validation.length === 0) {
          if (showPreview) {
            response = await submitPreviewContent(
              withConfig("BUSINESS_LISTING_PREVIEW_URL"),
              contentPayload
            );
          } else {
            response = await submitByContentType(
              withConfig("BUSINESS_LISTING_CREATION_URL"),
              contentPayload
            );
          }
        }
        break;
      case CLASSIFIED:
        validation = validateRegularContent(MANDATORY_FIELDS_FOR_CLASSIFIED);
        if (validation.length === 0) {
          if (showPreview) {
            response = await submitPreviewContent(
              withConfig("CLASSIFIED_PREVIEW_URL"),
              contentPayload
            );
          } else {
            response = await submitByContentType(
              withConfig("CLASSIFIED_CREATION_URL"),
              contentPayload
            );
          }
        }
        break;
      case REAL_ESTATE_LISTING:
        validation = validateRegularContent(MANDATORY_FIELDS_FOR_REAL_ESTATE);
        if (showPreview) {
          validation = validateRegularContent(
            MANDATORY_FIELDS_FOR_REAL_ESTATE_PREVIEW
          );
        }

        if (validation.length === 0) {
          if (showPreview) {
            response = await submitPreviewContent(
              withConfig("REAL_ESTATE_PREVIEW_URL"),
              contentPayload
            );
          } else {
            response = await submitByContentType(
              withConfig("REAL_ESTATE_CREATION_URL"),
              contentPayload
            );
          }
        }
        break;
      case OBITUARY:
        let mandatory_obituary_fields = MANDATORY_FIELDS_FOR_OBITUARY;
        if (showPreview) {
          mandatory_obituary_fields = MANDATORY_FIELDS_FOR_OBITUARY_PREVIEW;
        }

        validation = validateRegularContent(mandatory_obituary_fields);

        if (validation.length === 0) {
          if (showPreview) {
            response = await submitPreviewContent(
              withConfig("OBITUARY_PREVIEW_URL"),
              contentPayload
            );
          } else {
            response = await submitByContentType(
              withConfig("OBITUARY_CREATION_URL"),
              contentPayload
            );
          }
        }
        break;
      case LEGAL_NOTICE:
        const mandatory_fields = MANDATORY_FIELDS_FOR_LEGAL_NOTICE;
        validation = validateRegularContent(mandatory_fields);

        if (validation.length === 0) {
          if (showPreview) {
            response = await submitPreviewContent(
              withConfig("PREVIEW_LEGAL_NOTICES"),
              contentPayload
            );
          } else {
            response = await submitByContentType(
              withConfig("CREATE_LEGAL_NOTICES"),
              contentPayload
            );
          }
        }
        break;
      default:
        break;
    }

    return response;
  };

  const updateContentForm = async (url, mandatoryFieldsForValidations) => {
    setContentBody();
    let validation;
    const contentPayload = buildContentPayload();
    let response = { errors: [""] };
    console.log({ contentPayload });

    validation = validateRegularContent(mandatoryFieldsForValidations, true);

    if (validation.length === 0) {
      try {
        const response = await submitByContentType(url, contentPayload, PUT);

        if (response?.data?.errors) {
          setFormErrors(response?.data?.errors);
        }

        return response;
      } catch (error) {
        console.error({ error });
        setFormErrors(error);
      }
    }
    return response;
  };

  return {
    setProperty,
    submitForm,
    submitDraftForm,
    updateContentForm,
  };
};

export default useSubmitableContentForm;
