import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import labels from "../../../../config/labels";
import TextField from "../../../../common/TextField/TextField";
import { useStyles } from "./ArticleFields.style";
import useSubmitableContentForm from "../../useSubmitableContentForm";
import {
  HEADLINE,
  SUBHEADING,
  AUTHORS_NAME,
  MANDATORY_FIELDS,
  MANDATORY_FIELDS_FOR_COLUMN,
  PHONE_NUMBER,
  MANDATORY_PHONE_NUMBER_ERROR,
  LETTERS_TO_THE_EDITOR,
  CANDIDATE_STATEMENTS,
  COLUMN,
  COLUMN_ID,
  CONTENT_ID,
  MANDATORY_TOWN_OF_RESIDENCY_ERROR,
  TOWN_OF_RESIDENCY,
  ORGANIZATION_TYPE,
  LTE_QUESTION,
  FREELANCE,
  FREELANCE_EMAIL_AUTHOR,
  FREELANCE_EMAIL,
  AD_FREE,
} from "../../diyConstants";
import Dropdown from "../../../../common/Dropdown/Dropdown";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import { getDropdownChoices } from "../../../../services/townService";

const ArticleFields = ({ formType, formErrors, columns, content, adFree }) => {
  const classes = useStyles();
  const { setProperty } = useSubmitableContentForm();
  const [columnChoiceId, setColumnChoiceId] = useState();

  const shouldMarkError = (prop) => {
    let validation;

    if (prop === PHONE_NUMBER) {
      validation = MANDATORY_PHONE_NUMBER_ERROR;
      if (formErrors.includes(MANDATORY_PHONE_NUMBER_ERROR)) {
        return true;
      }
    } else if (prop === TOWN_OF_RESIDENCY) {
      validation = MANDATORY_TOWN_OF_RESIDENCY_ERROR;
      if (formErrors.includes(MANDATORY_TOWN_OF_RESIDENCY_ERROR)) {
        return true;
      }
    } else {
      if (formType === COLUMN) {
        validation = MANDATORY_FIELDS_FOR_COLUMN.find(
          (mandatory) => mandatory.key === prop
        );
      } else {
        validation = MANDATORY_FIELDS.find(
          (mandatory) => mandatory.key === prop
        );
      }

      if (prop === AUTHORS_NAME && formType === COLUMN) {
        return false;
      }

      if (formErrors.includes(validation.error)) {
        return true;
      }
    }

    return false;
  };

  const handleColumnChoice = (choice) => {
    setColumnChoiceId(choice);
  };

  useEffect(() => {
    setProperty(COLUMN_ID, columnChoiceId);
    setProperty(AD_FREE, adFree);
  }, [columnChoiceId, adFree]);

  useEffect(() => {
    if (!!content?.id) {
      setProperty(CONTENT_ID, content?.id);
      setProperty(HEADLINE, content?.name);
      setProperty(SUBHEADING, content?.subheading);
      setProperty(AUTHORS_NAME, content?.byline);
      setProperty(PHONE_NUMBER, content?.phone_number);
      setProperty(ORGANIZATION_TYPE, content?.organization_type);
      setProperty(TOWN_OF_RESIDENCY, content?.town_of_residency);
      setProperty(LTE_QUESTION, content?.lte_question);
      setProperty(FREELANCE_EMAIL, content?.freelance_email);
      if (content?.column?.id) {
        setColumnChoiceId(content?.column?.id);
      }
    }
  }, [content]);

  return (
    <>
      <div className={classes.regular_text_field}>
        <TextField
          onChange={(e) => setProperty(HEADLINE, e.target.value)}
          label={labels.HEADLINE}
          error={shouldMarkError(HEADLINE)}
          required
          defaultValue={content?.name}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          onChange={(e) => setProperty(SUBHEADING, e.target.value)}
          label={labels.SUBHEADING}
          defaultValue={content?.subheading}
        />
      </div>
      <div className={classes.regular_text_field}>
        <TextField
          onChange={(e) => setProperty(AUTHORS_NAME, e.target.value)}
          label={
            formType === CANDIDATE_STATEMENTS
              ? labels.BYLINE
              : labels.AUTHORS_NAME
          }
          {...(formType === LETTERS_TO_THE_EDITOR && {
            sublabel: labels.CANNOT_BE_ANONYMOUS,
          })}
          error={shouldMarkError(AUTHORS_NAME)}
          required={formType === COLUMN ? false : true}
          defaultValue={content?.byline}
        />
      </div>
      {formType === COLUMN && (
        <Dropdown
          label={labels.COLUMNS}
          items={getDropdownChoices(columns, handleColumnChoice, false)}
          getChosenOptionId={handleColumnChoice}
          typographyLevel={screenWidthIsLowerThan(900) ? "medium_17_px" : "h4"}
          shouldPreselectFirstOption={columns?.length === 1}
          placeholder={columns ? labels.CHOOSE_A_COLUMN : labels.NO_COLUMNS}
          disabled={columns ? false : true}
          error={shouldMarkError(COLUMN_ID)}
          defaultValue={content?.column}
        />
      )}
      {formType === LETTERS_TO_THE_EDITOR && (
        <>
          <div className={classes.regular_text_field}>
            <TextField
              onChange={(e) => setProperty(PHONE_NUMBER, e.target.value)}
              label={labels.PHONE_NUMBER}
              sublabel={labels.FOR_VERIFICATION_PURPOSES}
              error={shouldMarkError(PHONE_NUMBER)}
              defaultValue={content?.phone_number}
              required
            />
          </div>
          <div className={classes.regular_text_field}>
            <TextField
              onChange={(e) => setProperty(TOWN_OF_RESIDENCY, e.target.value)}
              label={labels.TOWN_OF_RESIDENCY}
              error={shouldMarkError(TOWN_OF_RESIDENCY)}
              defaultValue={content?.town_of_residency}
              required
            />
          </div>
          <div className={classes.regular_text_field}>
            <TextField
              onChange={(e) => setProperty(LTE_QUESTION, e.target.value)}
              label={labels.LTE_QUESTION}
              defaultValue={content?.lte_question}
              inputProps={{ maxLength: 200 }}
              placeholder={labels.LTE_QUESTION_PLACE_HOLDER}
            />
          </div>
        </>
      )}
      {formType === FREELANCE && (
        <div className={classes.regular_text_field}>
          <TextField
            onChange={(e) => setProperty(FREELANCE_EMAIL, e.target.value)}
            label={FREELANCE_EMAIL_AUTHOR}
            defaultValue={content?.freelance_email}
            required
          />
        </div>
      )}
      {formType === CANDIDATE_STATEMENTS && (
        <div className={classes.regular_text_field}>
          <TextField
            onChange={(e) => setProperty(PHONE_NUMBER, e.target.value)}
            label={labels.PHONE_NUMBER}
            sublabel={labels.FOR_VERIFICATION_PURPOSES}
            defaultValue={content?.phone_number}
          />
        </div>
      )}
    </>
  );
};

ArticleFields.propTypes = {
  formType: PropTypes.string,
  formErrors: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.shape),
  content: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    body: PropTypes.string,
    byline: PropTypes.string,
    subheading: PropTypes.string,
    excerpt: PropTypes.string,
    phone_number: PropTypes.string,
    facebook_client_url: PropTypes.string,
  }),
};

export default ArticleFields;
