import React, { useState } from "react";
import Grid from "../../../common/Grid/Grid";
import Checkbox from "../../../common/Checkbox/Checkbox";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./OptionalSettings.style";
import labels from "../../../config/labels";
import { useDiy } from "../../../context/DiyContext";

const OptionalSettings = ({ contributionType, options }) => {
  const classes = useStyles();
  const { adFree, setAdFree } = useDiy();
  const [enews, setEnews] = useState(false);

  const showAdFree = [
    "press-release",
    "candidate-statements",
    "column",
    "event",
    "classified",
  ].includes(contributionType);

  const showIncludeEnews = true;

  const visibleOptions = options.filter(
    (option) =>
      (option.id !== "adFree" || showAdFree) &&
      (option.id !== "includeEnews" || showIncludeEnews)
  );
  const handleCheckboxChange = (id) => (event) => {
    if (id === "adFree") {
      setAdFree(event.target.checked);
    } else if (id === "includeEnews") {
      setEnews(event.target.checked);
    }
  };
  return (
    <div className={classes.container}>
      <Typography level={"medium_20_px"} className={classes.title}>
        {labels.TITLE}
      </Typography>
      {visibleOptions.map((option) => (
        <Grid container key={option.id} className={classes.optionRow}>
          <Grid item xs={1} lg={1} md={1} className={classes.checkboxWrapper}>
            <Checkbox
              onChange={handleCheckboxChange(option.id)}
              checkboxColor={"#7FC035"}
              checked={option.id === "adFree" ? adFree : enews}
            />
          </Grid>
          <Grid item xs={9} lg={9} md={9} className={classes.textWrapper}>
            <Typography level={"medium_18_px"} className={classes.optionTitle}>
              {option.title}
            </Typography>
            <Typography
              level={"medium_16_px"}
              className={classes.optionDescription}
            >
              {option.description}
            </Typography>
          </Grid>
          <Grid item xs={2} lg={2} md={2} className={classes.priceWrapper}>
            <Typography
              level={"medium_18_px"}
              className={classes.optionPrice}
              bold
            >
              {option.price}
            </Typography>
            <Typography className={classes.priceDetails}>
              {option.priceDetails}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default OptionalSettings;
